import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import cookie from 'react-cookies';
import moment from 'moment';
import Config from './config';

export const toastStyle = {
    zIndex: 1999
};

export const CanonicalNamePattern = /^[a-zA-Z_][a-zA-Z0-9_]*$/i;

class PalladientComponent extends Component {        
  
    constructor(props) {
        super(props);

        this.state = {
            alertVisible: false,
            alertMessage: "",
            alertColor: "info",
            loading: false,
            loadingMessage: "Loading...",
            tooltips: [],
            actionsEnabled: true,
        }

        this.goBack = this.goBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNumericInputChange = this.handleNumericInputChange.bind(this);
        this.isTooltipOpen = this.isTooltipOpen.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }
    
    defineState(state) {
        this.state = {...this.state, ...state};
    }

    goBack() {
        this.props.history.goBack();
    }
      
    showAlert(message, color) {
        this.setState({ alertVisible: true, alertMessage: message, alertColor: color });
    }

    hideAlert() {
        this.setState({ alertVisible: false });
    }
  
    handleChange = (event) => {
        if (event.target.type == 'checkbox') {
            this.setState({
                [event.target.id]: event.target.checked
            });
        } else {
            this.setState({
                [event.target.id]: event.target.value
            });      
        }
    }

    handleNumericInputChange(valueAsNumber, valueAsString, eventTarget) {
        this.setState({
            [eventTarget.id]: valueAsNumber
        });
    }

    handleApiRejection(error) {
        console.log(error);
        this.tryNavigate('/500');
    }

    handleApiRejectionInModal(error) {
        console.log(error);
        this.showAlert("An unexpected error occurred. The dev team are notified. Please try your request later.", "danger");
        this.setState({actionsEnabled: false});
    }

    screenApiResponse(response, errorOn404) {
        if (response.status === 401) {
            // session on server is toast. need to sign in.
            cookie.remove('token');
            sessionStorage.removeItem('session');
            this.tryNavigate('/signin');
            return response;
        }
        else if ((response.status === 404 && errorOn404) || response.status === 409) {
            this.showAlert(response.status + " - " + response.statusText, "warn");
        }
        else if (response.status === 500 || response.status === 400) {
            response.json().then(data => {
                console.log(data);
                if (data.exceptionMessage) {
                    this.showAlert(data.exceptionMessage, "danger")
                } else {
                    this.showAlert(response.statusText, "danger");
                }
            });
        }
        else if (response.status != 200 && response.status != 201) {
            // bad request or something unexpected.
            console.log(response);
            this.showAlert(response.status + " - " + response.statusText, "danger");
        } else {
            this.hideAlert();
            return response;
        }
    }

    screenApiResponseInModal(response) {
        if (response.status === 500) {
            response.json().then(data => {
                console.log(data);
                if (data.exceptionMessage) {
                    this.showAlert(data.exceptionMessage, "danger")
                } else {
                    this.showAlert(response.statusText, "danger");
                }
            });
        }
        else if (response.status != 201 && response.status != 200) {
            console.log(response);
            this.showAlert(response.status + " - " + response.statusText, "danger");
        } else{
            return response;
        }
    }

    tryNavigate(path) {
        if (this.props.history) this.props.history.push(path);
    }

    blurTargetOnEnterKey = event => {
        const keyCode = event.keyCode || event.which

        if (keyCode === 13) {
            event.target.blur();
            event.returnValue = false;
            if (event.preventDefault) event.preventDefault()
        }
    }

    toggleTooltip(tooltipId) {
        var tooltips = this.state.tooltips;
        var tooltip = tooltips.find(t => t.id === tooltipId);
        if (tooltip) tooltip.open = !tooltip.open;
        this.setState({tooltips:tooltips});
    }

    isTooltipOpen(tooltipId) {
        var tooltip = this.state.tooltips.find(t => t.id == tooltipId);
        if (tooltip) return tooltip.open;    
    }

    getTooltipText(tooltipId) {
        var tooltip = this.state.tooltips.find(t => t.id == tooltipId);
        return tooltip ? tooltip.text : tooltipId;    
    }

    toast(message) {
        return toast(message);
    }
    
    toastDanger(message) {
        return toast.error(message);
    }

    toastSuccess(message) {
        return toast.success(message);
    }

    toastInfo(message) {
        return toast.info(message);
    }

    toastWarn(message) {
        return toast.warn(message);
    }

    toastClear() {
        return toast.dismiss();
    }

    isLoading() {
        return this.state.loading;
    }

    startLoading(message) {
        if (!message) message = "Loading...";
        return this.setState({loading: true, loadingMessage: message});
    }

    stopLoading() {
        this.setState({loading: false});
    }    

    formatShortDate(date) {
        var formatted = moment(date).format('MM/DD/YYYY');
        if (formatted === '01/01/0001') return 'N/A';
        else return formatted;    
    }

    renderLoading(loadingMessage) {
        return (
            <Container>
                <Row className="justify-content-center">
                    <div className="sk-wave">
                    <div className="sk-rect sk-rect1"></div>&nbsp;
                    <div className="sk-rect sk-rect2"></div>&nbsp;
                    <div className="sk-rect sk-rect3"></div>&nbsp;
                    <div className="sk-rect sk-rect4"></div>&nbsp;
                    <div className="sk-rect sk-rect5"></div>
                    </div>
                </Row>
                <Row className="justify-content-center">
                    <p>{loadingMessage ? loadingMessage : this.state.loadingMessage}</p>
                </Row>
            </Container>      
        );
    }
} 

export default PalladientComponent;