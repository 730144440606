const customerNav = {
  items: [
    {
      name: 'Home',
      url: '/customer',
      icon: 'fa fa-home',
    },
    {
      name: 'Payments',
      url: '/customer/payments',
      icon: 'fa fa-dollar'
    },
    {
      name: 'Invoices',
      url: '/customer/invoices',
      icon: 'fa fa-tasks',
    },
    {
      name: 'Support',
      url: '/customer/support',
      icon: 'fa fa-ticket',
    },
    {
      name: 'Trainings',
      url: '/customer/trainings',
      icon: 'fa fa-rocket',
    },
    {
      name: 'Addons',
      url: '/customer/addons',
      icon: 'fa fa-puzzle-piece'
    }
  ]
}

export default customerNav;
