import React from 'react';
import PalladientComponent from '../../PalladientComponent';
import PropTypes from 'prop-types';
import {    Button, Table,
             Row, Col, CardHeader, Card, CardBody,
            Form, FormGroup, Input, Label, Alert } from 'reactstrap';
import { CustomerProvider, ProductProvider, CustomerContractProvider } from '../../providers';
import { formatUsCurrency } from '../../services/DataTableHelper';
import { ProductCostBasisEnum, DiscountStrategyEnum } from '../../enums';
import { PrintButton } from '../../components';
import moment from 'moment';
import AgreementTermsCard from './AgreementTermsCard';

const propTypes = {
    customer: PropTypes.object.isRequired,
    contract: PropTypes.object.isRequired,
    agreementTerms: PropTypes.object.isRequired,
    primarySubscription: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    invoiceLocation: PropTypes.object.isRequired,
    onAccepted: PropTypes.func,
    buttonDisabled: PropTypes.bool,
    inReadMode: PropTypes.bool,
}

class SubscriptionAgreementCard extends PalladientComponent {
    constructor(props) {
        super(props);

        this.defineState({
            confirmOrder: false,
        });

        this.products = [];
        this.contract = {};
        this.invoiceLocation= {};

        this.customerProvider = new CustomerProvider();
        this.productProvider = new ProductProvider();
        this.contractProvider = new CustomerContractProvider();

        this.handleAcceptClicked = this.handleAcceptClicked.bind(this);
    }

    handleAcceptClicked(e) {
        e.preventDefault();
        if (!this.state.confirmOrder) {
            this.showAlert("You must check the box to provide consent before continuing.", "warning");
        } else {
            this.hideAlert();
            if (this.props.onAccepted) this.props.onAccepted();
        }
    }

    getProduct(productId) {
        const product = this.props.products.find(p => p.id === productId);
        return product ? product : {};
    }

    getPriceTextForProduct(product) {
        if (product.costBasis === ProductCostBasisEnum.FixedPrice) {
            return "{0}/mo".format(formatUsCurrency(product.basePrice));
        } else if (product.costBasis === ProductCostBasisEnum.Consumption) {
            var text = "{0}/{1}/mo".format(formatUsCurrency(product.tiers[0].perUnitCost), product.consumptionCostUnit);
            if (product.tiers.length > 1) text += " ({0} more price tiers)".format(product.tiers.length-1);
            return text;
        } else {
            var text = "Base {0}/mo + {1}/{2} over {3}".format(formatUsCurrency(product.basePrice), formatUsCurrency(product.tiers[0].perUnitCost), product.consumptionCostUnit, product.consumptionCostThreshold);
            if (product.tiers.length > 1) text += " ({0} more price tiers)".format(product.tiers.length-1);
            return text;
        }
    }

    getDiscountText(subscription) {
        if (subscription.discountStrategy === DiscountStrategyEnum.None) {
            return '';
        } else if (subscription.discountStrategy === DiscountStrategyEnum.FixedAmount) {
            return 'Fixed price of ' + formatUsCurrency(subscription.discountAmount);
        } else if (subscription.discountStrategy === DiscountStrategyEnum.FixedReduction) {
            return '{0} off each month'.format(formatUsCurrency(subscription.discountAmount));
        } else {
            return '{0}% off each month'.format(subscription.discountAmount);
        }
    }

    renderSubscriptionFees(product) {
        if (product) {
            if (product.code === 'NBL' || product.code === 'NBSP') {
                return (
                    <>
                        <p>
                            The table below represents standard per-user pricing for the Noteable EHR Platform. Any modifications to
                            these terms will be shown below. <strong>Once active, you will be charged the minimum base price each
                            month, even if you do not have active users in that month.</strong>
                        </p>
                        {this.renderFeeTable(product)}
                    </>
                )
            } else if (product.code === 'NBL:ST' || product.code === 'NBSP-S') {
                return (
                    <>
                        <p>
                            {formatUsCurrency(product.basePrice)}/month for first {product.consumptionCostThreshold} users.
                            &nbsp;{formatUsCurrency(product.tiers[0].perUnitCost)}/month for users {product.tiers[0].unitMinCount} - 
                            {product.tiers[0].unitMaxCount}. Once user {Number(product.tiers[0].unitMaxCount) + 1} is added, 
                            pricing will switch to standard.
                        </p>
                        <p>
                            Once startup pricing no longer applies, the fee table below represents standard per-user pricing.
                            &nbsp;<strong>Once active, you will be charged the minimum base price each month, even if you do not 
                            have active users in that month.</strong>
                        </p>
                        {this.renderFeeTable(product)}
                    </>
                )
            } else if (product.code === 'NBL:GH') {
                return (
                    <>
                        <p>
                            {formatUsCurrency(product.tiers[0].perUnitCost)} per bed. Fee applies to beds whether or not they are occupied. Indicate 
                            number of beds in notes. If this number changes you MUST notify us or be in breach of this agreement.
                        </p>
                    </>
                )
            } else if (product.code === 'NBL:GH2') {
                return (
                    <>
                        <p>
                            {formatUsCurrency(product.basePrice)} for the first {product.consumptionCostThreshold} beds. 
                            {formatUsCurrency(product.tiers[0].perUnitCost)} per bed per month after that. Fee applies to 
                            beds whether or not they are occupied. Indicate number of beds in notes. If this number changes 
                            you MUST notify us or be in breach of this agreement.
                        </p>
                    </>
                )
            } else if (product.code === 'NBL:ADS' || product.code === 'NABADS') {
                return (
                    <>
                        <p>
                            {formatUsCurrency(product.basePrice)} per month for the first {product.consumptionCostThreshold} clients. 
                            {formatUsCurrency(product.tiers[0].perUnitCost)} per client, per month, for additional clients.
                        </p>
                    </>
                )
            }
        }
    }

    renderFeeTable(product) {
        return (
            <Table bordered>
                <thead>
                    <tr>
                        <th>Active Users</th>
                        <th>Monthly</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Base Price</td>
                        <td>{formatUsCurrency(product.basePrice)}, up to {product.consumptionCostThreshold} users</td>
                    </tr>
                    {
                        product.tiers.map(t => (
                            <tr key={'fee_table_tier_' + t.tierNumber}>
                                <td>{product.consumptionCostUnit}s {t.unitMinCount} {t.unitMaxCount === 0 ? 'and up' : ' - ' + t.unitMaxCount}</td>
                                <td>{formatUsCurrency(t.perUnitCost)}/{product.consumptionCostUnit.toLowerCase()}</td>
                            </tr>    
                        ))
                    }
                </tbody>
            </Table>
        )
    }

    renderAddonDetails() {
        if (this.props.contract.addonProductIds) {
            let addons = this.props.contract.addonProductIds.map(a => {
                const product = this.props.products.find(p => p.id === a);
                return product;
            });
    
            return (
                <>
                    <ul>
                        {
                            addons.map((addon, index) => (
                                <li key={addon.id}>
                                    {addon.name} - {addon.description} - {this.getPriceTextForProduct(addon)}
                                </li>
                            ))
                        }
                    </ul>
                </>
            )    
        }

        return '';
    }

    render() {
        let customer = this.props.customer,
            contract = this.props.contract,
            invoiceLocation = this.props.invoiceLocation,
            product = this.getProduct(contract.initialProductId);
        
        return (
            <>
                <Row>
                    <Col className="text-right">
                        <PrintButton
                        target="subscription-agreement-content"
                        windowTitle="Noteable Subscription Agreement"
                        color="primary"
                        height={800}
                        width={900}
                        className="ml-auto mb-2"
                        bodyClasses="m-4"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody id="subscription-agreement-content">
                                <Row>
                                    <Col className="text-center">
                                        <h5>Noteable Order Form</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table borderless size="sm">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Customer:</strong></td>
                                                    <td>{customer.name}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Authorized Person:</strong></td>
                                                    <td>{customer.billingContactName}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Email Address:</strong></td>
                                                    <td>{customer.billingEmail}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Billing Address:</strong></td>
                                                    <td>
                                                        {invoiceLocation.addressLine1}<br/>
                                                        {invoiceLocation.addressLine2 ? (invoiceLocation.addressLine2 + '</br>') : ''}
                                                        {invoiceLocation.city}, {invoiceLocation.stateOrRegion} {invoiceLocation.postalCode}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr className="text-muted"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table borderless size="sm">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Initial Services:</strong></td>
                                                    <td>
                                                        <p>
                                                            Initial service fees for the setup of your Noteable account include:
                                                        </p>
                                                        <ol>
                                                            <li>Support on the general configuration of your tenancy on Noteable, including program settings and billing rules</li>
                                                            <li>Initial implementation of report templates</li>
                                                            <li>One administrator training, virtual</li>
                                                            <li>One supervisor training, virtual</li>
                                                            <li>One clinician/counselor training, virtual</li>
                                                            <li>Expert business analysis and consulting</li>
                                                        </ol>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Initial Service Fees:</strong></td>
                                                    <td>{formatUsCurrency(contract.upfrontCost)}</td>
                                                </tr>
                                                <tr>
                                                    <td width="180px"><strong>Base Subscription Type:</strong></td>
                                                    <td>{product.name}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>{product.description}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Subscription Fees:</strong></td>
                                                    <td>
                                                        {this.renderSubscriptionFees(product)}
                                                        {
                                                            this.props.primarySubscription.discountStrategy !== DiscountStrategyEnum.None ? (
                                                                <p>
                                                                    <strong>Discount applied: </strong>
                                                                    {this.getDiscountText(this.props.primarySubscription)}
                                                                </p>
                                                            ) : ''
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Addons:</strong></td>
                                                    <td>
                                                        {this.renderAddonDetails()}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Custom Terms:</strong></td>
                                                    <td>{contract.additionalTerms}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Preferred Go-live Date:</strong></td>
                                                    <td>
                                                        <p>
                                                            {moment(contract.contractedGoLiveDate).format('MM-DD-yyyy')}
                                                        </p>
                                                        <p className="font-weight-bold">
                                                            Noteable reserves the right to adjust this date as needed to accommodate your
                                                            enrollment needs. You will be notified if this date needs to be adjusted. You 
                                                            agree that Noteable will begin sending monthly usage-based invoices once your
                                                            go-live date arrives.
                                                        </p>    
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr className="text-muted"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AgreementTermsCard agreementTerms={this.props.agreementTerms} />
                                    </Col>
                                </Row>
                                {
                                    !this.props.inReadMode ? (
                                        <React.Fragment>
                                            <Row className="mb-2">
                                                <Col>
                                                    <Form>
                                                        <FormGroup check className="mb-2">
                                                            <Label check>
                                                                <Input type="checkbox" id="confirmOrder" checked={this.state.confirmOrder} onChange={this.handleChange} />{' '}
                                                                I, {customer.billingContactName}, an authorized agent of {customer.name}, have reviewed and consent to the terms listed above.
                                                            </Label>
                                                        </FormGroup>
                                                        <hr className="text-muted"/>
                                                        <Row form>
                                                            <Col className="text-center">
                                                                <Button color="primary" size="lg" disabled={this.props.buttonDisabled} onClick={this.handleAcceptClicked}>Accept</Button> 
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-center">
                                                <Col>
                                                    <Alert color={this.state.alertColor} isOpen={this.state.alertVisible} toggle={this.hideAlert}>{this.state.alertMessage}</Alert>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Row className="mb-2">
                                                <Col className="text-center">
                                                    <span className="font-weight-bold"><small>Agreement accepted {moment(this.props.contract.customerSignedOn).format('MM/DD/yyyy')} by {this.props.contract.customerSignerEmail}</small></span>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

SubscriptionAgreementCard.propTypes = propTypes;

export default SubscriptionAgreementCard;