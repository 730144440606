import React, { Component } from 'react';
import { 
      Button, Card, CardBody, Col, Collapse, Container, Form, FormFeedback, FormGroup, FormText,
      Input, Label, InputGroup, InputGroupAddon, InputGroupText, Row, Alert } from 'reactstrap';
import logo from '../../../assets/img/brand/logo.png'
import { CustomerProvider, RapidEnrollmentProvider, CustomerEnrollmentProvider } from '../../../providers';
import { CustomerEnrollmentStatusEnum } from '../../../enums';
import 'spinkit/css/spinkit.css';
import queryString from 'query-string';
import PalladientComponent from '../../../PalladientComponent';
import NewCustomerInfoPanel from './NewCustomerInfoPanel';
import SubscriptionAgreementPanel from './SubscriptionAgreementPanel';
import PaymentInfoPanel from './PaymentInfoPanel';

const logoStyle = {
    height: '50px',
    textAlign: 'center',
    marginBottom: '15px'
}
  
class RapidEnrollmentPage extends PalladientComponent {
    constructor(props) {
        super(props);

        this.defineState({
          currentStep: -1,
          enrollment: {},
          agreementData: null,
          processingPayment: false,
        });

        this.customerProvider = new CustomerProvider();
        this.customerEnrollmentProvider = new CustomerEnrollmentProvider();
        this.rapidEnrollmentProvider = new RapidEnrollmentProvider();
    }

    componentDidMount() {
      this.startLoading();
      const qs = queryString.parse(this.props.location.search);
      if (!qs.invitationId) {
        this.stopLoading();
        this.showAlert('You must be here by mistake. You have not provided a confirmation token.');
      } else {
        this.customerEnrollmentProvider.getOne(qs.invitationId)
        .then(response => this.screenApiResponse(response))
        .then(response => response && response.json())
        .then(enrollment => {
         if (!enrollment || enrollment.status === "Cancelled" || enrollment.status === "Expired") {
            this.showAlert("The confirmation token is not valid", "danger");
            this.setState({currentStep: -1});
            return;
          }

          this.setState({enrollment: enrollment});

          if (enrollment.status === "Pending" || enrollment.status === "Sent") {
            this.setState({currentStep: 0});
          } else if (enrollment.status === "Confirmed") {
            // customer previously completed the enrollment step. To continue, need to load the agreement data
            // and determine which step they left off on.
            this.loadAgreementData(enrollment.id)
            .then(agreementData => {
              if (!agreementData) {
                this.showAlert("Something went wrong. Please contact your Noteable representative to continue");
                return;
              }
      
              this.setState({agreementData: agreementData});

              const customer = agreementData.customer;
      
              if (customer.enrollmentStatus === CustomerEnrollmentStatusEnum.Committed) {
                // Customer needs to accept agreement.
                this.setState({currentStep: 1});
              } else if (customer.enrollmentStatus === CustomerEnrollmentStatusEnum.Signed) {
                // Customer needs to enter payment details.
                this.setState({currentStep: 2});
              } else {
                // Enrollment is complete
                this.setState({currentStep: 3});
              }      
            });
          }
        })
        .catch(error => this.handleApiRejection(error))
        .finally(() => this.stopLoading());
      }
    }

    loadAgreementData(enrollmentId) {
      return this.rapidEnrollmentProvider.getEnrollmentAgreement(enrollmentId)
      .then(response => this.screenApiResponse(response))
      .then(response => response && response.json())
      .then(data => {
        return data;
      })
      .catch(error => this.handleApiRejection(error));
    }
  
    handleCheckRequestCompleted(data) {
      this.setState({
        enrollment: data,
      });
    }

    handleCustomerInfoFormCompleted(enrollment) {
      this.customerEnrollmentProvider.patch(this.state.enrollment.id, enrollment)
      .then(response => this.screenApiResponse(response))
      .then(updatedEnrollment => {
        this.loadAgreementData(this.state.enrollment.id)
        .then(data => {
          if (!data) {
            this.showAlert("Something went wrong. Please contact your Noteable representative to continue", "danger");
            return;
          }
          this.setState({agreementData: data, currentStep: 1});
        });
      })
      .catch(error => this.handleApiRejection(error));
    }

    handleAgreementAccepted() {
      this.hideAlert();
      this.setState({saving: true});
      // the customer has signed
      this.rapidEnrollmentProvider.signEnrollmentAgreement(this.state.enrollment.id)
      .then(response => this.screenApiResponse(response))
      .then(response => response && response.json())
      .then(responseData => {
        let agreementData = this.state.agreementData;
        agreementData.customer = responseData.customer;
        agreementData.contract = responseData.contract;
        agreementData.setupInvoice = responseData.setupInvoice;
        this.setState({agreementData: agreementData, currentStep: 2});
      })
      .catch(error => this.handleApiRejection(error));    
    }

    handlePaymentInfoUpdated(paymentInfo) {
      this.hideAlert();
      this.setState({processingPayment: true});
      // payment info provided
      this.rapidEnrollmentProvider.savePaymentProfile(this.state.enrollment.id, paymentInfo)
      .then(response => this.screenApiResponse(response))
      .then(response => response && response.json())
      .then(response => {
        if (response) {
          // payment info has been saved successfully. Possible that the setup invoice payment
          // failed, but we'll just show a warning if that's the case.
          let agreementData = this.state.agreementData;
          agreementData.customer = response.customer;
          agreementData.setupInvoice = response.setupInvoice;
          this.setState({agreementData: agreementData, currentStep: 3});
        }
      })
      .catch(error => this.handleApiRejection(error))
      .finally(() => this.setState({processingPayment: false}));
    }

    getSigningUrl(contractId) {
      this.contractProvider.getSigningUrl(contractId)
      .then(response => this.screenApiResponse(response))
      .then(response => response && response.json())
      .then(signUrlResponse => {
          return signUrlResponse;
      })
      .catch(error => this.handleApiRejection(error));
    }

    render() {
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="9">
                <Alert color={this.state.alertColor} isOpen={this.state.alertVisible} toggle={this.hideAlert}>{this.state.alertMessage}</Alert>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="9">
                <Card className="p-4">
                  <CardBody>
                    <Container>
                      <Row className="justify-content-center">
                        <img src={logo} style={logoStyle} alt="Logo" />
                      </Row>
                      <Row className="justify-content-center">
                        <h2>Account Management and Billing Center</h2>
                      </Row>
                      <Row>
                        <Col>
                          <hr className="text-dark"/>
                        </Col>
                      </Row>
                    </Container>
                    {
                      this.isLoading() ? this.renderLoading() : (
                        <>
                          <Collapse isOpen={this.state.currentStep === 0}>
                            <Row>
                              <Col xs="2" md="1">
                                <h2>1.</h2>
                              </Col>
                              <Col xs="10" md="11">
                                <Row>
                                  <Col>
                                    <h4>Welcome to Noteable!</h4>
                                    <p>
                                      We are ready to start your onboarding process, where we will help you configure your site,
                                      design your note and report templates and train your staff. First, lets get you signed up!
                                      Please fill in some info for us.
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <NewCustomerInfoPanel
                                    enrollment={this.state.enrollment}
                                    onFormCompleted={this.handleCustomerInfoFormCompleted.bind(this)}/>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Collapse>
                          <Collapse isOpen={this.state.currentStep === 1}>
                            <Row>
                              <Col xs="2" md="1">
                                <h2>2.</h2>
                              </Col>
                              <Col xs="10" md="11">
                                <Row>
                                  <Col>
                                    <h4>Sign the Agreement</h4>
                                    <p>
                                        Please look over the subscription details below and make sure they are what you were
                                        expecting. Keep in mind this is not a long term contract. <strong>You can cancel your subscription
                                        at any time</strong>, with 30 days notice.
                                    </p>
                                    <p>
                                        To continue, you must scroll to the bottom of the agreement, check the box to provide consent and click 'Accept'.
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    {
                                      this.state.agreementData ? (
                                        <SubscriptionAgreementPanel
                                        agreementData = {this.state.agreementData}
                                        onAgreementAccepted = {this.handleAgreementAccepted.bind(this)}
                                        />    
                                      ) : (<></>)
                                    }
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Collapse>
                          <Collapse isOpen={this.state.currentStep === 2}>
                            <Row>
                              <Col xs="2" md="1">
                                <h2>3.</h2>
                              </Col>
                              <Col xs="10" md="11">
                                <Row>
                                  <Col>
                                    <h4>Enter Payment Details</h4>
                                    <p>
                                      Please provide a method of payment, to be used now for setup fees and for monthly subscription invoices. You
                                      will be able to change this information at any time by logging into the account management portal.
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    {
                                      this.state.agreementData && this.state.agreementData.setupInvoice ? (
                                        <PaymentInfoPanel
                                        customer = {this.state.agreementData.customer}
                                        setupInvoice = {this.state.agreementData.setupInvoice}
                                        onPaymentInfoUpdated = {this.handlePaymentInfoUpdated.bind(this)}
                                        isProcessing = {this.state.processingPayment}
                                        />
                                      ) : (<></>)
                                    }
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Collapse>
                          <Collapse isOpen={this.state.currentStep === 3}>
                            <Row>
                              <Col xs="2" md="1">
                                <h2>4.</h2>
                              </Col>
                              <Col xs="10" md="11">
                                <Row>
                                  <Col>
                                    <h4>That's it!</h4>
                                    <p>
                                      You are ready to begin onboarding. Our team will reach out shortly to schedule trainings, and
                                      plan your site configuration. You will also need to sign a HIPAA Business Associate Agreement.
                                      We're excited to work with you and look forward to serving your agency.
                                    </p>
                                    <p>
                                      In the meantime, you can sign into the Account Management Portal using the information you've
                                      just provided. Here you can see your invoices, current usage and estimated fees, manage your
                                      payment info, and more. Just click the link below to sign in.
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Collapse>
                        </>
                      )
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
                <Button color="link" className="px-0" href="/#/signin">Go to sign-in page</Button>
            </Row>
          </Container>
        </div>
      );
    }
}

export default RapidEnrollmentPage;