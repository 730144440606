import React from 'react';
import PalladientComponent from '../../PalladientComponent';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

const propTypes = {
    agreementTerms: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
}

class AgreementTermsCard extends PalladientComponent {

    render() {
        return (
            <Card className={this.props.className} style={this.props.style}>
                <CardBody id="agreement-window" dangerouslySetInnerHTML={{__html:this.props.agreementTerms.text}}>
                </CardBody>
            </Card>
        );
    }
}

AgreementTermsCard.propTypes = propTypes;

export default AgreementTermsCard;